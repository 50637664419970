import React, {useRef} from 'react';
import NextStepButton from "../components/next-step-button";
import IntroductionForm, {IntroductionFormHandle} from "../components/introduction-form";
import {useStepperIntroduction} from "../hooks/use-steps-introduction";

const StepTwo = () => {
    const {nextStep} = useStepperIntroduction();
    const formRef = useRef<IntroductionFormHandle>(null);

    const handleNextStep = () => {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    };

    return (
        <section className='flex h-full max-h-fit items-center justify-center'>
            <div
                className='h-full flex flex-col gap-y-20 justify-center md:!justify-center landscape:justify-between  overflow-y-auto'>
                <div className='flex flex-col gap-y-6'>
                    <header>
                        <h2 className='text-dark font-semibold text-2xl theme-dark:text-gray-700'>
                            Chcemy Cię lepiej poznać. Podaj nam kilka informacji o sobie.
                        </h2>
                    </header>
                    <IntroductionForm ref={formRef} onSubmitSuccess={nextStep}/>
                </div>
                <NextStepButton customHandler={true} handler={handleNextStep}/>
            </div>
        </section>
    );
}

export default StepTwo;
