import React from "react";
import DynamicView from "../../view-builder/dynamic-view";
import {TypeParent} from "../../view-builder/types/type-parent.interface";
import useAuth from "../../auth/hooks/useAuth";
import {useStepperIntroduction} from "../hooks/use-steps-introduction";
import {StepIntroduction} from "../types/steps-introduction.interface";
import PrimaryButton from "../../common/components/primary-button";
import {FaceSmileIcon} from "@heroicons/react/24/outline";

const StepOne = () => {
    const {currentUser} = useAuth();
    const {nextStep, currentStep} = useStepperIntroduction();

    const handleSkipStep = () => {
        if (!currentUser?.introductionScreenName && currentStep === StepIntroduction.STEP_ONE) {
            nextStep();
        }
    }

    return (
        <section className='h-full flex gap-y-6 mx-auto theme-dark:text-gray-700'>
            {currentUser?.introductionScreenName ? (
                <DynamicView
                    typeView={TypeParent.SCREEN}
                    parentName={currentUser?.introductionScreenName ?? ''}
                    endAction={nextStep}
                    endButtonIcon={FaceSmileIcon}
                    endButtonText={'Poznajmy się'}
                    errorFetching='Nie udało się wczytać danych wprowadzenia.'
                />
            ) : (
                <div className='flex-grow h-full flex flex-col gap-20 items-center justify-center'>
                    <img src={require("../../../assets/images/welcome_to_app.png")} alt='welcome' />
                    <PrimaryButton onClick={handleSkipStep}>Poznajmy się</PrimaryButton>
                </div>
            )}
        </section>
    );
};

export default StepOne;
