import React, {useEffect, useRef} from 'react';
import {BaseBlock} from "../types/base-block.interface";
import {
    CompletedPhrasesBodyRequest,
    HashedPhraseToComplete,
    IPhraseCompletionBlock
} from "../types/blocks/phrase-completion-block.interface";
import SentenceInput from "../components/ui/sentence-input";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useFormManager} from "../../core/hooks/use-form-manager";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {defaultFormConfig} from "../constants/default-form-config";

interface FormValues {
    answers: { blockContentId: number, answer: string }[];
}

const SentenceCompletionBlock: React.FC<BaseBlock<IPhraseCompletionBlock>> = (props: BaseBlock<IPhraseCompletionBlock>) => {
    const {data, id, blockCode,readonly} = props;
    const {sentence, hashedPhrasesToComplete, userCompletedPhrases} = data;

    const {registerForm, unregisterForm, validateForms} = useFormManager();
    const {sendUserAnswer} = useDynamicViewApi();

    const schema = Yup.object().shape({
        answers: Yup.array()
            .of(
                Yup.object().shape({
                    blockContentId: Yup.number()
                        .required(),
                    answer: Yup.string()
                        .required()
                        .min(1, 'Odpowiedź nie może być pusta.')
                })
            )
            .required()
    });

    const methods = useForm<FormValues>({
        ...defaultFormConfig,
        resolver: yupResolver(schema),
        mode:'all',
        defaultValues: {
            answers: hashedPhrasesToComplete.map(phrase => ({
                blockContentId: phrase.blockContentId,
                answer: userCompletedPhrases.find(completed => completed.blockContentId === phrase.blockContentId)?.contentOfCompletedPhrase || ''
            }))
        },
        disabled: readonly,
    });

    const originalValueRef = useRef(methods.getValues('answers'));

    const handleInputChange = (blockContentId: number, newAnswer: string) => {
        const updatedAnswers = methods.getValues('answers').map(answerObj =>
            answerObj.blockContentId === blockContentId
                ? {...answerObj, answer: newAnswer}
                : answerObj
        );
        methods.setValue('answers', updatedAnswers);
    };

    const onSubmit = (data: FormValues) => {
        if (JSON.stringify(data.answers) !== JSON.stringify(originalValueRef.current)) {
            originalValueRef.current = data.answers;
            const mappedBody = mapToRequestBody(data);
            sendUserAnswer(id, blockCode, mappedBody)
                .then()
                .catch()
                .finally();
        }
    };

    const mapToRequestBody = (data: FormValues): CompletedPhrasesBodyRequest => {
        return {
            answers: data.answers.map(el => ({
                blockContentId: el.blockContentId,
                sentence: el.answer ? el.answer : null,
            }))
        }
    }

    const renderSentenceWithInputs = (
        sentence: string,
        phrases: HashedPhraseToComplete[],
    ) => {
        let currentIndex = 0;
        const elements = [];

        phrases.forEach((phrase, index) => {
            const startIndex = sentence.indexOf(phrase.hashedPhrase, currentIndex);
            const endIndex = startIndex + phrase.hashedPhrase.length;

            if (startIndex > currentIndex) {
                elements.push(
                    <span key={`text-${currentIndex}`}>{sentence.slice(currentIndex, startIndex)}</span>
                );
            }

            elements.push(
                <SentenceInput
                    key={phrase.blockContentId.toString()}
                    name={`answers[${index}].answer`}
                    index={index}
                    onBlur={() => onSubmit(methods.getValues())}
                    onChange={(e) => handleInputChange(phrase.blockContentId, e.target.value)}
                />
            );

            currentIndex = endIndex;
        });

        if (currentIndex < sentence.length) {
            elements.push(
                <span key={`text-${currentIndex}`}>{sentence.slice(currentIndex)}</span>
            );
        }

        return elements;
    };

    useEffect(() => {
        registerForm(id, schema, methods.getValues, methods.trigger);
        return () => unregisterForm(id);
    }, [id]);

    return (
        <FormProvider {...methods}>
            <form id={id.toString()}>
                <div>{renderSentenceWithInputs(sentence, hashedPhrasesToComplete)}</div>
            </form>
        </FormProvider>
    );
};

export default SentenceCompletionBlock;
