import {BaseBlock} from "../types/base-block.interface";
import {BLOCK_CODE} from "../types/block-codes.interface";
import {BLOCK_CATEGORY} from "../types/block-categories.interface";
import {IHeaderBlock} from "../types/blocks/header-block.interface";
import {IParagraphBlock} from "../types/blocks/paragraph-block.interface";
import {IVideoBlock} from "../types/blocks/video-block.interface";
import {IShortAnswerBlock} from "../types/blocks/short-answer-block.interface";
import {ILongAnswerBlock} from "../types/blocks/long-answer-block.interface";
import {ISingleChoiceBlock} from "../types/blocks/single-choice-block.interface";
import {IMultipleChoiceAnyBlock} from "../types/blocks/multiple-choice-any-block.interface";
import {IMultipleChoiceFixedRangeBlock} from "../types/blocks/multiple-choice-fixed-range-block.interface";
import {IMultipleChoiceFixedCountBlock} from "../types/blocks/multiple-choice-fixed-count-block.interface";
import {IPhraseCompletionBlock} from "../types/blocks/phrase-completion-block.interface";
import {IPhrasePyramidBlock} from "../types/blocks/phrase-pyramid-block.interface";
import {IAddToCalendarManualBlock} from "../types/blocks/add-to-calendar-manual-block.interface";
import {IAddToCalendarAutomaticBlock} from "../types/blocks/add-to-calendar-automatic-block.interface";
import {IAudioRecordingBlock} from "../types/blocks/audio-recording-block.interface";
import {IVideoRecordingBlock} from "../types/blocks/video-recording-block.interface";
import {IAnimationBlock} from "../types/blocks/animation-block.interface";
import {ISeparatorBlock} from "../types/blocks/seperator-block.interface";
import {BlockDTO} from "../types/get-blocks-response.interface";
import {BLOCK_TYPE} from "../types/block-types.interface";
import {IPollBlock} from "../types/blocks/poll-block.interface";
import {INextStepBlock} from "../types/blocks/next-step-block.interface";
import {DataBlock} from "../types/block.interface";
import {CounterInteractiveBlocks} from "../types/counter-interactive-blocks.interface";
import {SetDataBlock} from "../types/set-data-block.interface";
import {SetDataBlockResult} from "../types/set-data-block-result.interface";
import {MapToGenericBlock} from "../types/map-to-generic-block.interface";
import {INextStepAsAnswerBlock} from "../types/blocks/next-step-as-answer-block.interface";

export const mapToGenericBlock: MapToGenericBlock = (
    dto: BlockDTO,
    counterInteractiveBlocks: CounterInteractiveBlocks,
    orderBlock: number,
    readonly: boolean
): BaseBlock<DataBlock> => {
    const baseBlock: BaseBlock<any> = {
        id: dto.id,
        ordinalNumber: dto.ordinalNumber || 0,
        chapterName: dto.chapterName || "",
        screenName: dto.screenName || "",
        blockCode: dto.blockCode as BLOCK_CODE,
        blockType: dto.blockType as BLOCK_TYPE,
        blockInteractive: dto.blockInteractive || false,
        blockCategory: dto.blockCategory as BLOCK_CATEGORY,
        data: null,
        isCompleted: false,
        orderBlock: orderBlock,
        readonly: readonly
    };

    const {data, hasAnswer} = setDataBlock(dto, baseBlock);

    baseBlock.data = data;
    baseBlock.isCompleted = hasAnswer;

    return baseBlock;
};

const setDataBlock: SetDataBlock = (dto: BlockDTO, baseBlock: BaseBlock<null>): SetDataBlockResult => {
    let isCompleted = false;

    const data = (() => {
        switch (baseBlock.blockCode) {
            case BLOCK_CODE.HEADER:
                return {
                    titleHTML: dto.header || "",
                } as IHeaderBlock;
            case BLOCK_CODE.PARAGRAPH:
                return {
                    contentHTML: dto.paragraph || "",
                } as IParagraphBlock;
            case BLOCK_CODE.VIDEO:
                return {
                    url: dto.videoUrl || "",
                } as IVideoBlock;
            case BLOCK_CODE.SHORT_ANSWER:
                isCompleted = !!dto.userAnswerBlock?.shortAnswer;
                return {
                    question: dto.qshortAnswer || "",
                    userAnswer: dto.userAnswerBlock?.shortAnswer,
                } as IShortAnswerBlock;
            case BLOCK_CODE.LONG_ANSWER:
                isCompleted = !!dto.userAnswerBlock?.longAnswer;
                return {
                    question: dto.qlongAnswer || "",
                    userAnswer: dto.userAnswerBlock?.longAnswer
                } as ILongAnswerBlock;
            case BLOCK_CODE.SINGLE_CHOICE:
                isCompleted = dto.blockContents?.some(answer => answer.userAnswerBlockContent?.selected) || false;
                return {
                    question: dto.qsingleChoice,
                    selectedAnswer: dto.blockContents
                        ?.find(answer => {
                            if (answer.userAnswerBlockContent?.selected) return {
                                id: answer.id,
                                name: answer.content
                            }
                        }) || null,
                    answers: dto.blockContents?.map(answer => ({id: answer.id, name: answer.content})) || [],
                } as ISingleChoiceBlock;
            case BLOCK_CODE.MULTIPLE_CHOICE_ANY:
                isCompleted = dto.blockContents?.some(answer => answer.userAnswerBlockContent?.selected) || false;
                return {
                    question: dto.qmultipleChoiceFreedom,
                    selectedAnswers: dto.blockContents
                        ?.filter(answer => {
                            if (answer.userAnswerBlockContent?.selected) return {
                                id: answer.id,
                                name: answer.content
                            }
                        }) || null,
                    answers: dto.blockContents?.map(answer => ({id: answer.id, name: answer.content})) || [],
                } as IMultipleChoiceAnyBlock;
            case BLOCK_CODE.MULTIPLE_CHOICE_FIXED_RANGE:
                isCompleted = dto.blockContents?.some(answer => answer.userAnswerBlockContent?.selected) || false;
                return {
                    question: dto.qmultipleChoice,
                    selectedAnswers: dto.blockContents
                        ?.filter(answer => {
                            if (answer.userAnswerBlockContent?.selected) return {
                                id: answer.id,
                                name: answer.content
                            }
                        }) || null,
                    answers: dto.blockContents?.map(answer => ({id: answer.id, name: answer.content})) || [],
                    range: {
                        min: dto.minRangeForMultipleChoice,
                        max: dto.maxRangeForMultipleChoice
                    }
                } as IMultipleChoiceFixedRangeBlock;
            case BLOCK_CODE.MULTIPLE_CHOICE_FIXED_COUNT:
                isCompleted = dto.blockContents?.some(answer => answer.userAnswerBlockContent?.selected) || false;
                return {
                    question: dto.qmultipleChoiceSpecificNumber,
                    selectedAnswers: dto.blockContents
                        ?.filter(answer => {
                            if (answer.userAnswerBlockContent?.selected) return {
                                id: answer.id,
                                name: answer.content
                            }
                        }) || null,
                    answers: dto.blockContents?.map(answer => ({id: answer.id, name: answer.content})) || [],
                    fixedCount: dto.exactNumberOfAnswers,
                } as IMultipleChoiceFixedCountBlock;
            case BLOCK_CODE.PHRASE_COMPLETION:
                const sentence = dto.contentOfSentence || "";

                const hashedPhrasesToComplete = dto.blockContents?.map((block) => ({
                    blockContentId: block.id,
                    hashedPhrase: block.content,
                    isCompleted: !!block.userAnswerBlockContent?.sentence,
                }));

                const userCompletedPhrases = dto.blockContents
                    ?.filter((block) => block.userAnswerBlockContent?.sentence)
                    .map((block) => ({
                        blockContentId: block.id,
                        hashedPhrase: block.content,
                        contentOfCompletedPhrase: block.userAnswerBlockContent?.sentence || undefined,
                    })) || []

                isCompleted = hashedPhrasesToComplete?.length === userCompletedPhrases.length;
                return {
                    sentence,
                    hashedPhrasesToComplete,
                    userCompletedPhrases: userCompletedPhrases || [],
                } as IPhraseCompletionBlock;
            case BLOCK_CODE.PHRASE_PYRAMID:
                isCompleted = true;

                return {
                    question: dto.qpyramid,
                    phrases: dto.blockContents?.map(c => ({
                        idPhrase: c.id,
                        content: c.content,
                        ordinalNumber: c.userAnswerBlockContent?.pyramidOrdinalNumber!
                    })).sort((a, b) => a.ordinalNumber - b.ordinalNumber)
                } as IPhrasePyramidBlock;
            case BLOCK_CODE.ADD_TO_CALENDAR_MANUAL:
                return {} as IAddToCalendarManualBlock;
            case BLOCK_CODE.ADD_TO_CALENDAR_AUTOMATIC:
                return {
                    calendarTypeId: dto.calendarTypeId || "",
                    calendarTitle: dto.calendarTitle || "",
                    hourForStartDate: dto.hourForStartDate?.toString() || "",
                    daysCountForStartDate: dto.daysCountForStartDate || 0,
                    daysCountForEndDate: dto.daysCountForEndDate || 0,
                    hourForEndDate: dto.hourForEndDate?.toString() || "",
                } as IAddToCalendarAutomaticBlock;
            case BLOCK_CODE.AUDIO_RECORDING:
                isCompleted = !!dto.userAnswerBlock?.audioPath;
                return {
                    audioPath: dto.userAnswerBlock?.audioPath,
                } as IAudioRecordingBlock;
            case BLOCK_CODE.VIDEO_RECORDING:
                isCompleted = !!dto.userAnswerBlock?.videoPath;
                return {
                    videoPath: dto.userAnswerBlock?.videoPath || "",
                    userAnswer: dto.userAnswerBlock ? {
                        videoPath: dto.userAnswerBlock.videoPath
                    } : undefined,
                } as IVideoRecordingBlock;

            case BLOCK_CODE.ANIMATION:
                return {
                    question: dto.qanimation
                } as IAnimationBlock;
            case BLOCK_CODE.SEPARATOR:
                return {
                    content: null,
                } as ISeparatorBlock;
            case BLOCK_CODE.POLL:
                isCompleted = !!dto.userAnswerBlock?.pollAnswer;
                return {
                    question: dto.qpoll,
                    userAnswer: dto.userAnswerBlock
                } as IPollBlock;
            case BLOCK_CODE.NEXT_STEP:
                isCompleted = !!dto.userAnswerBlock?.completed;
                return {
                    isLastBlockInScenario: false,
                    connectWithApi: true,
                } as INextStepBlock;
            case BLOCK_CODE.NEXT_STEP_AS_ANSWER:
                isCompleted = dto.blockContents?.some(answer => answer.userAnswerBlockContent?.selected) || false;
                return {
                    question: dto.qmoveOnAsAnswer,
                    selectedAnswer: dto.blockContents
                        ?.find(answer => {
                            if (answer.userAnswerBlockContent?.selected) return {
                                id: answer.id,
                                name: answer.content
                            }
                        }) || null,
                    answers: dto.blockContents?.map(answer => ({id: answer.id, name: answer.content})) || [],
                } as INextStepAsAnswerBlock;
            default:
                throw new Error(`Unsupported block type: ${baseBlock.blockCode}`);
        }
    })();

    return {data, hasAnswer: isCompleted};
};

