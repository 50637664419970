import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ThemeProvider} from "./modules/core/contexts/theme-context";
import {BackgroundProvider} from "./modules/settings/contexts/background-context";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
        <ThemeProvider>
            <BackgroundProvider>
                <App/>
            </BackgroundProvider>
        </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to registerAccount() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        console.log('New update available'); // Dodaj ten log
        if (window.confirm('Nowa wersja aplikacji jest dostępna. Chcesz odświeżyć?')) {
            if (registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        }
    },
    enableAutoCheck: true,
    intervalCheckingUpdates:300000
});

navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Service Worker has been updated. Reloading the page...');
    window.location.reload();
});


