import {BaseBlock} from "../types/base-block.interface";
import {IEndBlock} from "../types/blocks/end-block.interface";
import {BLOCK_CODE} from "../types/block-codes.interface";
import {BLOCK_CATEGORY} from "../types/block-categories.interface";
import {BLOCK_TYPE} from "../types/block-types.interface";
import {DataBlock} from "../types/block.interface";

export function createEndBlock(exampleBlock: BaseBlock<DataBlock>, orderBlock: number): BaseBlock<IEndBlock> {
    return {
        id: 1,
        ordinalNumber: 1,
        blockInteractive: false,
        isCompleted: false,
        blockCode: BLOCK_CODE.END_BLOCK,
        blockCategory: BLOCK_CATEGORY.STATIC,
        blockType: BLOCK_TYPE.BUTTONS_AND_OTHERS,
        chapterName: exampleBlock.chapterName,
        screenName: exampleBlock.screenName,
        orderBlock: orderBlock,
        readonly: exampleBlock.readonly,
        data: {
            label: "Zakończ rozdział"
        }
    }
}
