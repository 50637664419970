import Modal from "../../common/components/modal";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import withModalProvider from "../../common/hoc/withModalProvider";
import React, {useEffect} from "react";
import {useModal} from "../../common/hooks/useModal";
import {ArrowRightStartOnRectangleIcon, InboxIcon} from "@heroicons/react/24/outline";
import PrimaryButton from "../../common/components/primary-button";
import useAuth from "../hooks/useAuth";

const EmailConfirmation = () => {
    const {sendEmailConfirmationToken, currentUser, loading, logout} = useAuth();
    const {openModal} = useModal();

    const handleSendEmailConfirmationToken = () => {
        if (currentUser?.email) {
            sendEmailConfirmationToken(currentUser?.email).catch(() => {
                // Obsługa błędu (opcjonalna)
            });
        }
    };

    useEffect(() => {
        openModal(ModalIds.EMAIL_CONFIRMATION);
    }, []);

    return (
        <div>
            <Modal id={ModalIds.EMAIL_CONFIRMATION} showClose={false} disableBackdropClick={true}>
                <section className="flex flex-col gap-6 items-center">
                    <header className="flex flex-col gap-6 items-center">
                        <InboxIcon className="w-6 h-6 text-green-600" />
                        <h2 className="text-lg leading-6 font-semibold text-gray-900">
                            Sprawdź swoją skrzynkę e-mail
                        </h2>
                    </header>
                    <p className="text-sm leading-5 font-normal text-gray-500 text-center">
                        Aby rozpocząć korzystanie z aplikacji, potwierdź swój adres e-mail. Sprawdź skrzynkę pocztową
                        (w tym folder spam), aby znaleźć wiadomość z linkiem potwierdzającym. Jeśli nie otrzymałeś wiadomości, kliknij
                        „Wyślij ponownie”.
                    </p>
                    <div className="flex gap-3">
                        <PrimaryButton
                            onClick={handleSendEmailConfirmationToken}
                            loading={loading}
                            disabled={loading}
                            styleClass="max-w-fit h-fit py-1 px-3 text-sm leading-4 font-medium"
                        >
                            Wyślij ponownie
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={logout}
                            loading={loading}
                            disabled={loading}
                            styleClass="max-w-fit h-fit py-1 px-3 text-sm leading-4 bg-transparent
                                !text-primary font-medium hover:!bg-primary_700 hover:!text-white transition"
                        >
                            <div className="flex items-center justify-center">
                                <ArrowRightStartOnRectangleIcon className="mr-3 h-5 w-5" />
                                <span>Wyloguj</span>
                            </div>
                        </PrimaryButton>
                    </div>
                </section>
            </Modal>
        </div>
    );
};

export default withModalProvider(EmailConfirmation);
